
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M24 32h336a23.94 23.94 0 0124 24v16a23.94 23.94 0 01-24 24H24A23.94 23.94 0 010 72V56a23.94 23.94 0 0124-24z","opacity":".4","fill":"currentColor"}}),_c('path',{attrs:{"d":"M56.13 321.48l-17.06-17a23.86 23.86 0 010-33.91L175 135.05a24 24 0 0134 0l135.93 135.52a23.86 23.86 0 010 33.91l-17.06 17a24 24 0 01-34 0l-65.81-65.61V456A24 24 0 01204 480h-24.09a24 24 0 01-24.07-24V255.87L90 321.48a23.9 23.9 0 01-33.87 0z","fill":"currentColor"}})])
          )
        }
      }
    